import React from 'react';
import colors from 'tailwindcss/colors';

const Logo = ({color}: {color?: string}) => {
  return (
    <div className="flex items-center">
      <svg width="32px" height="40px" viewBox="0 0 32 40" version="1.1">
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Group-17" fill={color || colors.cyan[500]}>
            <path
              d="M32,0 C32,5.6 32,10.9333333 32,16 C32,21.0666667 32,26.4 32,32 C26.6666667,32 21.3333333,32 16,32 C10.6666667,32 5.33333333,32 0,32 C7.73333333,29.0666667 14.2666667,24.9333333 19.6,19.6 C24.9333333,14.2666667 29.0666667,7.73333333 32,0 Z"
              id="Rectangle"
            />
            <rect id="Rectangle" x="8" y="36" width="24" height="4"></rect>
          </g>
        </g>
      </svg>
      <div className="mx-3 font-semibold text-xl antialiased">sails</div>
    </div>
  );
};

export default Logo;
