import React, { useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import Logo from './Logo';
import { MenuIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

const Header = ({ className }: { className?: string }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          appUrl
        }
      }
    }
  `);

  const [isOpen, setIsOpen] = useState(false);

  const [scrolledFromTop, setScrolledFromTop] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const SCROLL_TOP_MIN = 70;
      setScrolledFromTop(window.scrollY > SCROLL_TOP_MIN);
    };

    document.addEventListener('scroll', onScroll);
    onScroll();
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <header
      className={classNames(
        'fixed top-0 left-0 w-full z-50 backdrop-filter backdrop-blur-lg bg-opacity-90 transition-colors',
        scrolledFromTop || isOpen ? 'bg-white' : className
      )}
    >
      <div className="py-5 container mx-auto relative">
        <div className="flex justify-between flex-wrap md:flex-nowrap">
          <div>
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <button
            className="appearance-none block md:hidden p-2 rounded active:bg-gray-100"
            onClick={() => setIsOpen((f) => !f)}
          >
            <MenuIcon className="w-5 h-5" />
          </button>
          <nav
            className={
              (isOpen ? 'relative block pt-4 top-full z-30 w-full' : 'hidden') +
              ' md:flex items-center md:w-auto md:pt-0'
            }
          >
            <Link
              to="/features"
              className="header-link"
              activeClassName="bg-gray-900 bg-opacity-5"
            >
              Features
            </Link>
            <Link
              to="/why"
              className="header-link"
              activeClassName="bg-gray-900 bg-opacity-5"
            >
              Hvorfor Sails
            </Link>
            <Link
              to="/pricing"
              className="header-link"
              activeClassName="bg-gray-900 bg-opacity-5"
            >
              Priser
            </Link>
            <Link
              to="/support"
              className="header-link"
              activeClassName="bg-gray-900 bg-opacity-5"
            >
              Support
            </Link>
            <div className="md:mx-1 h-0.5 w-full md:h-5 md:w-0.5 bg-gray-500 bg-opacity-10 block" />
            <a href={site.siteMetadata.appUrl} className="header-link">
              Log ind
            </a>
            <a
              href={`${site.siteMetadata.appUrl}/auth/sign-up`}
              className="btn-sm"
            >
              Kom igang
            </a>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
