import React, { ReactNode, ReactNodeArray } from 'react';
import Footer from './Footer';
import Header from './Header';

const Layout = ({
  children,
  headerClass,
}: {
  children?: ReactNode | ReactNodeArray;
  headerClass?: string;
}) => {
  return (
    <>
      <div className="flex flex-col h-full">
        <Header className={'flex-shrink-0 ' + (headerClass || '')} />
        <main className="flex-grow">{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
