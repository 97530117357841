import { Link } from 'gatsby';
import React from 'react';
import colors from 'tailwindcss/colors';
import Logo from './Logo';

const Footer = () => (
  <div className="bg-cyan-800 px-4 py-32 text-cyan-50 flex-shrink-0 flex flex-col items-center">
    <Logo color={colors.cyan[500]} />
    <p className="text-lg md:text-xl font-medium my-4">
      Hav en fantastisk dag.
    </p>
    <nav className="text-sm text-cyan-400 mb-4 text-center">
      <Link className="px-2 py-1 inline-block hover:underline" to="/features">
        Features
      </Link>
      <Link className="px-2 py-1 inline-block hover:underline" to="/why">
        Hvorfor Sails?
      </Link>
      <Link className="px-2 py-1 inline-block hover:underline" to="/pricing">
        Priser
      </Link>
      <Link className="px-2 py-1 inline-block hover:underline" to="/support">
        Support
      </Link>
      <Link className="px-2 py-1 inline-block hover:underline" to="/contact">
        Kontakt
      </Link>
      <Link className="px-2 py-1 inline-block hover:underline" to="/terms">
        Betingelser
      </Link>
      <Link className="px-2 py-1 inline-block hover:underline" to="/privacy">
        Privatliv
      </Link>
      <Link className="px-2 py-1 inline-block hover:underline" to="/dpa">
        Databehandleraftale
      </Link>
    </nav>
    <p className="text-sm text-center">
      Sails er designet og udviklet af{' '}
      <a className="underline" href="https://nexagon.dk">
        Nexagon
      </a>
      .
    </p>
    <p className="text-sm text-center mt-1">
      Copyright © 2021 Nexagon ApS. Alle rettigheder forbeholdes.
    </p>
  </div>
);

export default Footer;
